<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6">
        <JockeyPendingMoves/>
      </v-col>
      <v-col cols="12" sm="6">
        <JockeyMoveHistory/>
      </v-col>
      <v-dialog v-if="showScheduleMoveComponent" :persistent="true" v-model="showScheduleMoveComponent" max-width="800">
        <schedule-move selectionType = "partial"/>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import JockeyPendingMoves from './jockeyPendingMoves.vue';
import JockeyMoveHistory from './jockeyMoveHistory.vue';
import ScheduleMove from './scheduleMove.vue';

export default {
  components: { JockeyPendingMoves, JockeyMoveHistory, ScheduleMove },
  data: () => ({
    interval: null,
  }),
  computed: {
    ...mapGetters('webSocket', ['isYardSocketConnected']),
    ...mapGetters('move', ['showScheduleMoveComponent']),
  },
  watch: {
    isYardSocketConnected(isConnected) {
      if (!isConnected) {
        const intervalTime = 10000; // 10 seconds;

        this.interval = setInterval(() => {
          this.refreshPendingMoves();
          this.getCompletedMovesToday(true);
        }, intervalTime);
      } else {
        clearInterval(this.interval);
      }
    },
  },
  methods: {
    ...mapActions('move', [
      'getPendingMoves',
      'getCompletedMovesToday',
      'refreshPendingMoves',
    ]),
  },
  created() {
    this.getPendingMoves(true);
    this.getCompletedMovesToday(true);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
