<template>
    <v-card height="85vh">
      <v-card-title>Completed Moves Today</v-card-title>
      <v-data-table
        :headers="headers"
        :items="completedMovesToday"
        v-bind:disable-pagination="true"
        v-bind:hide-default-footer="true"
        v-bind:fixed-header="true"
        height="75vh"
        :loading="isLoadingCompletedMoves"
        loading-text="Loading... Please wait"
      >
        <template v-slot:item.in="{ item }">
          <td>{{ formatDate(item.in) }}</td>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    headers: [
      { text: 'Created By', value: 'createdBy', align: 'start' },
      { text: 'Completed By', value: 'assignedTo' },
      { text: 'From', value: 'fromLocation.displayName' },
      { text: 'To', value: 'toLocation.displayName' },
      { text: 'Equipment', value: 'equipment.type' },
      { text: 'Number', value: 'equipment.number' },
    ],
  }),
  computed: {
    ...mapGetters('move', ['completedMovesToday', 'isLoadingCompletedMoves']),
  },
  methods: {
    formatDate: (date) => {
      const d = new Date(date);
      return d.toLocaleString('en-US', { timeZone: 'America/New_York' });
    },
  },
};
</script>
