<template>
<div>
  <jockey></jockey>
</div>
</template>

<script>
import jockey from '@/components/jockey.vue';

export default {
  name: 'JockeyView',
  components: { jockey },
};
</script>
