<template>
  <v-card>
      <spot-selection
        :selectionType= this.selectionType
        :shouldApplyColors="true"
        @spot-selected="spotSelected"
      />
    <v-card-title class="pb-n4 mb-n4">
      <v-container>
        <v-row class="justify-center">
          <v-col cols="12">
            <span>Select location to move this equipment to</span>
          </v-col>
        </v-row>
        <v-row class="pb-n2 mb-n2">
          <v-col cols="auto" class="pb-1 mb-1 pl-4 ml-4">
            <span class="py-0 my-0">Number: {{getEquipmentNumber}}</span>
          </v-col>
        </v-row>
        <v-row class="py-n2 my-n2">
          <v-col cols="auto" class="py-1 my-1 pl-4 ml-4">
            <span class="py-0 my-0">Type: {{getEquipmentType}}</span>
          </v-col>
        </v-row>
        <v-row class="pt-n2 mt-n2">
          <v-col cols="auto" class="pt-1 mt-1 pl-4 ml-4">
            <span class="py-0 my-0">State: {{getEquipmentState}}</span>
          </v-col>
        </v-row>
        <v-row v-if="isDoor">
          <v-col cols="auto" class="pt-0 mt-0 pl-4 ml-4">
            <v-select
              :items="equipmentStateItems"
              v-model="equipmentState"
              label="Loaded Status *"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto" class="pr-0">
            <h2>Moving From: {{ getMoveFrom }}</h2>
          </v-col>
          <v-col cols="auto">
            <v-icon large> arrow_forward </v-icon>
          </v-col>
          <v-col cols="auto" class="pt-4">
            <v-btn @click="selectClicked" color="primary">Select</v-btn>
          </v-col>
          <v-col cols="3" class="pt-0">
            <v-text-field disabled label="Location" v-model="to"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text>
      <v-container fluid flex> </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined :disabled="!hasMoveToLocation" @click="submit" color="primary">Submit</v-btn>
        <v-btn color="primary" outlined @click="reset"> Close </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import spotSelection from './spotSelectionDialog.vue';
import locationMapper from '../mappers/locationMapper';
import equipmentService from '../services/equipmentService';

export default {
  props: ['selectionType'],
  data() {
    return {
      updatedEquipmentState: null,
    };
  },
  created() {
    this.setMoveFromLocation(this.fromLocation);
    this.setMoveEquipment(this.equipment);
    this.setMoveCreatedBy(this.fullName);
  },
  components: { spotSelection },
  computed: {
    fromLocation() {
      return this.equipmentLocation(this.equipment.id);
    },
    equipmentState: {
      get() {
        return this.move.equipment.state;
      },
      set(value) {
        this.updatedEquipmentState = value;
      },
    },
    equipmentStateItems: {
      get() {
        return equipmentService.getEquipmentStateOptions(this.equipment.type);
      },
    },
    getEquipmentNumber() {
      return this.equipment.number;
    },
    getEquipmentType() {
      return this.equipment.type;
    },
    getEquipmentState() {
      return this.equipment.state;
    },
    getMoveFrom() {
      return this.fromLocation.displayName;
    },
    getFromGroup() {
      return this.fromLocation.groups[0];
    },
    isDoor() {
      const re = /Door/;
      const isMatch = re.exec(this.getFromGroup);
      return isMatch;
    },
    to: {
      get() {
        return this.move.toLocation.displayName;
      },
      set(val) {
        this.setMoveToLocation(val);
      },
    },
    ...mapGetters('location', ['equipmentLocation']),
    ...mapGetters('account', ['fullName']),
    ...mapGetters('move', ['move', 'hasMoveToLocation', 'pendingMoveByLocationAndEquipment']),
    ...mapGetters({ equipment: 'equipment/selectedEquipmentToMove' }),
  },
  methods: {
    ...mapActions({
      saveMove: 'move/saveMove',
      updateMove: 'move/updateMove',
      setShowSpotSelectionDialog: 'location/setShowSpotSelectionDialog',
      setShowComponent: 'move/setShowScheduleMoveComponent',
      setMoveFromLocation: 'move/setMoveFromLocation',
      setMoveToLocation: 'move/setMoveToLocation',
      setAssignedTo: 'move/setAssignedTo',
      setStartDate: 'move/setStartDate',
      setMoveEquipment: 'move/setMoveEquipment',
      setMoveCreatedBy: 'move/setMoveCreatedBy',
    }),
    submit() {
      const existingPendingMove = this.pendingMoveByLocationAndEquipment(this.move.fromLocation.id, this.move.equipment.id);
      if (existingPendingMove > '') {
        this.updateMove({ updatedMove: this.move, existingMoveId: existingPendingMove.id });
      } else {
        this.saveMove({ move: this.move, equipmentState: this.updatedEquipmentState });
      }
      this.reset();
    },
    reset() {
      this.setMoveToLocation(locationMapper.defaultLocation);
      this.setAssignedTo('');
      this.setStartDate(null);
      this.setShowComponent(false);
    },
    spotSelected(location) {
      this.to = location;
    },
    selectClicked() {
      this.setShowSpotSelectionDialog(true);
    },
  },
};
</script>
