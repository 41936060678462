<template>
  <v-dialog :persistent="true" v-model="showDialog" max-width="800">
    <v-card>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col md="8">
              <span>Select a location to view available spots</span>
            </v-col>
            <v-col md="4">
              <v-select
                :items="this.groupNames"
                v-model="selectedBlock"
                label="Location"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="selectionType === 'full'">
            <v-sheet elevation="2" height="20" width="20" color="red"></v-sheet>
            &nbsp;&nbsp;&nbsp;
            <span>Occupied</span>
            &nbsp;&nbsp;&nbsp;
            <v-sheet elevation="2" height="20" width="20" color="yellow"></v-sheet>
            &nbsp;&nbsp;&nbsp;
            <span>Scheduled Move To</span>
          </v-row>
        </v-container>
      </v-card-title>

      <v-card-text>
        <v-progress-circular
          indeterminate
          size="100"
          v-if="isLoading"
          class="offset-md-5"
        ></v-progress-circular>
        <v-container fluid flex v-if="!isLoading">
          <v-row>
            <v-col
              v-for="location in this.availableLocations"
              :key="location.displayName"
              xs="1"
              cols="auto"
              class="pr-0"
            >
              <v-sheet
                :color="getLocationColor(location)"
                elevation="1"
                height="50"
                width="50"
                class="d-flex justify-center align-center"
                style="cursor: pointer"
                v-on:click="spotSelected(location)"
                >{{ location.displayName }}</v-sheet
              >
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showDialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    selectionType: String,
    shouldApplyColors: Boolean,
  },
  computed: {
    showDialog: {
      get() {
        return this.showSpotSelectionDialog;
      },
      set(value) {
        if (!value) {
          this.setShowSpotSelectionDialog(false);
        }
      },
    },
    selectedBlock: {
      get() {
        return this.selectedGroup;
      },
      set(value) {
        this.setSelectedGroup(value);
      },
    },
    availableLocations: {
      get() {
        if (this.selectionType === 'full') {
          return this.locationsByGroup(this.selectedBlock);
        }
        if (this.selectionType === 'partial') {
          return this.allUnstructuredOrAvailableLocationsByGroup(this.selectedBlock);
        }
        return this.locationsBelowMaxCapacityByGroup(this.selectedBlock);
      },
    },
    ...mapGetters('location', ['isLoading', 'showSpotSelectionDialog', 'groupNames', 'selectedGroup', 'locationsByGroup',
      'locationsBelowMaxCapacityByGroup', 'allUnstructuredOrAvailableLocationsByGroup', 'isLocationAtCapacity']),
  },
  methods: {
    ...mapActions('location', ['setShowSpotSelectionDialog', 'setSelectedGroup']),
    spotSelected(spot) {
      this.$emit('spot-selected', spot);
      this.showDialog = false;
    },
    getLocationColor(location) {
      if (this.shouldApplyColors) {
        if (this.isLocationAtCapacity(location)) {
          return 'red';
        }
        if (location.hasMoveTo) {
          return 'yellow';
        }
      }

      return 'white';
    },
  },
  mounted() {
    this.setSelectedGroup(this.groupNames[0]);
  },
};
</script>
