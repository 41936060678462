<template>
    <v-card height="85vh">
      <v-card-title>
        Pending Moves&nbsp;&nbsp;&nbsp;
        <v-avatar color="grey darken-3" size="27">
          <span class="white--text">{{ this.filteredPendingMoves.length }}</span>
        </v-avatar>
        <v-spacer> </v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          label="Search"
          v-model="searchTerm"
          single-line
          hide-details>
        </v-text-field>
      </v-card-title>
      <v-progress-circular
        indeterminate
        size="100"
        v-if="isPageLoading"
        class="offset-md-5"
      ></v-progress-circular>
      <v-list
        v-if="!isPageLoading"
        three-line
        max-height="75vh"
        style="overflow-y: auto; overflow-x: hidden">
        <draggable v-model="pendingMoves" handle=".draggable">
          <v-list-item v-for="(item, index) in pendingMoves" :key="index">
            <v-container fluid class="px-0">
              <v-list-item-content>
                <v-row>
                  <v-col cols="auto pt-0 mt-0 pr-0 mr-0" class="draggable">
                    <v-icon class="h4-custom-size">reorder</v-icon>
                  </v-col>
                  <v-col cols="auto" class="pl-0 ml-0">
                    <v-row class="ml-0">
                      <v-col cols="auto" class="pt-0 mt-0 pb-1 pr-0 mr-1">
                        <h3 class="h3-custom-size font-weight-bold">FROM: {{ item.fromLocation.groups[0] }}-Spot {{ item.fromLocation.displayName}}<br>
                        TO: {{ item.toLocation.groups[0] }}-Spot {{ item.toLocation.displayName }}</h3>
                        <span class="blue--text" v-if="item.isMoving">In Progress</span>
                      </v-col>
                    </v-row>
                    <v-row class="ml-0">
                      <v-col cols="auto" class="pt-0 mt-0 pb-0 pr-0 mr-0">
                        <h4 class="h4-custom-size font-weight-regular">
                          {{ item.equipment.type }} - {{ item.equipment.number }}
                        </h4>
                        <h4 v-if="item.assignedTo" class="h4-custom-size font-weight-regular">
                          Started By: {{ item.assignedTo }}
                        </h4>
                      </v-col>
                      <v-col cols="auto" class="pt-0 mt-0 pb-0 pl-1 pr-0">
                        <v-icon v-if="isDeleteable(item)" @click="deleteMove(item)" class="h4-custom-size">delete</v-icon>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class=" pb-4 pl-0 ml-0">
                    <v-row justify="end">
                      <v-col cols="auto" class="pt-0 mt-0 pb-1">
                        <v-btn
                          @click="updateMove(item)"
                          color="neutral"
                          class="pl-1 pr-1 h4-custom-size"
                          v-if="canUpdateLocation(item)">
                          Change
                        </v-btn>
                      </v-col>
                      <v-col cols="auto" class="pt-0 mt-0 pb-1">
                        <v-btn
                          @click="start(item)"
                          color="primary"
                          class="pl-1 pr-1 h4-custom-size"
                          :disabled="isStartDisabled(item.toLocation)"
                          v-if="!item.assignedTo">
                          Start
                        </v-btn>
                        <v-btn
                          @click="finish(item)"
                          :disabled="isFinishDisabled(item.assignedTo)"
                          color="secondary"
                          class="pl-1 pr-1 h4-custom-size"
                          v-if="item.assignedTo">
                          Finish
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-container>
          </v-list-item>
        </draggable>
      </v-list>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default {
  components: { draggable },
  computed: {
    ...mapGetters('location', ['isLoading', 'isLocationAtCapacity']),
    ...mapGetters('move', ['filteredPendingMoves', 'isLoadingPendingMoves', 'filter']),
    ...mapGetters('account', ['fullName', 'isAdmin']),
    pendingMoves: {
      get() {
        return this.filteredPendingMoves;
      },
      set(value) {
        this.setPendingMoves(value);
      },
    },
    searchTerm: {
      get() {
        return this.filter;
      },
      set(value) {
        this.setFilter(value);
      },
    },
    isPageLoading() {
      return this.isLoading || this.isLoadingPendingMoves;
    },
  },
  methods: {
    ...mapActions('equipment', ['setEquipmentToMove']),
    ...mapActions('move', [
      'startMove',
      'stopMove',
      'removeMove',
      'setPendingMoves',
      'setFilter',
      'setShowScheduleMoveComponent',
      'resetMove',
    ]),
    async start(move) {
      await this.startMove(move);
    },
    updateMove(move) {
      this.setEquipmentToMove(move.equipment);
      this.setShowScheduleMoveComponent(true);
    },
    finish(move) {
      this.stopMove(move);
      this.resetMove();
    },
    deleteMove(move) {
      this.removeMove(move);
    },
    isDeleteable(move) {
      const isAssigned = move.assignedTo > '';
      const nameMatches = move.assignedTo === this.fullName;

      if (!isAssigned || nameMatches || this.isAdmin) {
        return true;
      }
      return false;
    },
    canUpdateLocation(move) {
      const moveStarted = move.startDate > '';
      const nameMatches = move.assignedTo === this.fullName;

      if ((nameMatches || this.isAdmin) && moveStarted) {
        return true;
      }
      return false;
    },
    isStartDisabled(toLocation) {
      if (this.isLocationAtCapacity(toLocation)) {
        return true;
      }
      return false;
    },
    isFinishDisabled(assignedTo) {
      const nameMatches = assignedTo === this.fullName;
      if (nameMatches || this.isAdmin) {
        return false;
      }
      return true;
    },
  },
  beforeDestroy() {
    this.setFilter('');
  },
};
</script>

<style scoped>
  .draggable-flex {
    display: inherit;
    flex-direction: inherit;
  }
  .h3-custom-size {
    font-size: calc(0.00670*100vw + 7.33666px);
  }
  .h4-custom-size {
    font-size: calc(0.00623*100vw + 6.7332px);
  }
</style>
